document.addEventListener('DOMContentLoaded', function () {
    if (document.querySelector('[data-role="solarrechner"]') !== null) {

        window.onload = function() {
            const iframe = document.querySelector('[data-role="solarrechner"] iframe');
            iframe.height = iframe.contentWindow.document.body.scrollHeight;
        }

        window.addEventListener('message', (event) => {
            if (event.origin !== "https://test703.solar-toolbox.ch") {
                return;
            }

            if (event.data.height) {
                const iframe = document.querySelector('[data-role="solarrechner"] iframe');
                iframe.style.height = `${event.data.height}px`;
            }

            if (event.data.id && document.getElementById('myenergysolution-id')) {
                document.getElementById('myenergysolution-id').value = event.data.id;
                // set uuid in hidden text field
            }
            if (event.data.text && document.getElementById('wly_address')) {
                document.getElementById('wly_address').value = event.data.text;
            }

            console.log('Message received from the iframe:',
                event.data);

            // todo add hidden input field on contact form below solarrechner
        });
    }
});